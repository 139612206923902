import styled from "styled-components";
import { SectionWrapper } from "../../../shared/Styles/Wrappers";

export const Container = styled.div`
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '124px')};
    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '124px')};
    width: 100%;
    background-color: ${({ background }) => (background ? background : "#fff")};
    color: ${({ color }) => (color ? color : "#000")};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '64px')};
        padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '64px')};
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '36px')};
        padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : '36px')};
    }
`;

export const StylesSectionWrapper = styled(SectionWrapper)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

export const Item = styled.div`
    text-align: center;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.333%;
        margin-bottom: 36px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
        width: 50%;
    }
`;

export const Title = styled.div`
    font-size: 6rem;
    font-weight: 800;
    margin-bottom: 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 4.2rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        margin-bottom: 12px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 3.6rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
        margin-bottom: 6px;
    }
`;

export const Description = styled.p`
    font-size: 1.6rem;
    font-weight: 500;
    max-width: 142px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }
`;
