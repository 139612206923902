import React from 'react';
import { Container, Description, Item, StylesSectionWrapper, Title } from "./styles";

export const ContentNumbers = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Container background={data.background} color={data.color} paddingTop={data.paddingTop} paddingBottom={data.paddingBottom}>
      <StylesSectionWrapper>
        {data.items.map(element => (
          <Item key={element.description}>
            <Title>{element.title}</Title>
            {element.description && <Description>{element.description}</Description>}
          </Item>
        ))}
      </StylesSectionWrapper>
    </Container>
  );
};
