import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${({ color }) => (color ? color : "transparent")};
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    height: 100vh;
    max-height: -webkit-fill-available;
  }
`;

export const Wrapper = styled(SectionWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) and (orientation: portrait) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }
`;

export const Column = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    
  .gatsby-image-wrapper {
    max-width: 800px;
    max-height: 800px;
    width: 100%;
  }
    
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    .gatsby-image-wrapper {
      max-width: 700px;
      width: 100%;
    }
  }
    
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) and (orientation: portrait) {
    width: 100%;
    height: auto;
      
    .gatsby-image-wrapper {
      max-width: 500px;
    }
  }
    
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    transform: none !important;
  }
`;

export const CaseTitle = styled.div`
    font-size: 5rem;
    line-height: 1;
    color: ${({ color }) => (color ? color : "#fff")};
    font-weight: 800;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 4.2rem;
        line-height: 1.1;
    }

    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.laptop_small}) {
    }

    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.tablet_big}) {
        font-size: 3.4rem;
    }

    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.tablet_small}) and (orientation: portrait) {
        width: 100%;
        text-align: center;
    }

    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.phone_big}) and (orientation: portrait) {
        font-size: 3rem;
    }

    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        font-size: 2.8rem;
    }
    
    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.phone_big}) and (orientation: landscape) {
        font-size: 2.4rem;
    }
`;

export const VideoWrapper = styled.div`
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(19, 19, 19, 0.4);
        z-index: 2;
    }
`;
