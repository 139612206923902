import React, { useRef } from "react";
import { useScrollPosition } from "shared/Hooks/useScrollPosition";

import {
  Container,
  Wrapper,
  Column,
  CaseTitle,
  VideoWrapper
} from "./styles";
import { Video } from "../Video/video";

export const HeroVideo = ({ data }) => {
  const textRef = useRef(null);

  useScrollPosition(({ currPos }) => {
    textRef.current.style.transform = `translate3d(0,${-currPos.y * 0.3}px,0)`;
  });

  return (
    <Container color={data.color}>
      <VideoWrapper>
        <Video data={data.video}/>
      </VideoWrapper>
      <Wrapper>
        <Column ref={textRef} style={{ transform: "translate3d(0,0,0)" }}>
          <CaseTitle>{data.title}</CaseTitle>
        </Column>
      </Wrapper>
    </Container>
  );
};
