import { ContactForm, Layout, Navigation, WhatWeDid, } from "components/CaseLayout";
import SEO from "components/seo";
import React from "react";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";
import { HeroVideo } from "../../components/CaseLayout/HeroVideo/heroVideo";
import { ROWEROWAPRZYGODA } from "shared/Types/cases";
import { ContentPhoto, ContentVideo, FullPhoto, Team, TextBox } from "../../components/CaseLayout";
import { ContentNumbers } from "../../components/CaseLayout/ ContentNumbers/contentNumbers";
import { ContactBanner } from "../../components/CaseLayout/ContactBanner/contactBanner";

const RowerowaPrzygodaPage = () => {
  const data = useCaseData(ROWEROWAPRZYGODA);

  return (
    <Layout>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <HeroVideo data={data.hero}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={ROWEROWAPRZYGODA}/>
      <ContactBanner data={data.contact_banners.first}/>
      <TextBox data={data.text_boxes.first}/>
      <ContentVideo data={data.videos.first}/>
      <TextBox data={data.text_boxes.second}/>
      <ContentNumbers data={data.numbers.first}/>
      <ContentPhoto data={data.content_images.first} content={1400}/>
      <TextBox data={data.text_boxes.third}/>
      <ContentVideo data={data.videos.second}/>
      <TextBox data={data.text_boxes.fourth}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox data={data.text_boxes.fifth}/>
      <FullPhoto data={data.full_images.second}/>
      <TextBox data={data.text_boxes.sixth}/>
      <ContactBanner data={data.contact_banners.second}/>
      <TextBox data={data.text_boxes.seventh}/>
      <ContentPhoto data={data.content_images.second} content={1410}/>
      <TextBox data={data.text_boxes.eighth}/>
      <ContentPhoto data={data.content_images.third} content={1410}/>
      <Team data={data.team} swap={true} columns={5}/>
      <ContentPhoto data={data.content_images.fourth} content={1400}/>
      <TextBox data={data.text_boxes.ninth} color="#fff" background="#000"/>
      <ContentNumbers data={data.numbers.second}/>
      <ContentPhoto data={data.content_images.fifth} content={1400}/>
      <TextBox data={data.text_boxes.tenth}/>
      <ContentPhoto data={data.content_images.sixth} content={364}/>
      <ContactForm/>
    </Layout>
  );
};

export default RowerowaPrzygodaPage;
